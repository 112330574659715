import Logo from 'assets/caves/index/title-w-subtitle.svg';
import cn from 'classnames';
import { Wrapper } from 'components/base';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { content, copyright, crewBtn, desc, heading, isOpenC, logo, modal, modalWrapper, root, wrapper } from './IndexFooter.module.scss';

class IndexFooter extends Component {
	constructor(props) {
		super(props);
		this.state = {
      isOpen: false,
    };
	}

	openCrew = () => this.setState({ isOpen: true });

	closeCrew = () => this.setState({ isOpen: false });

	render() {
		const{ data: { bgImage } } = this.props;
		const { isOpen } = this.state;
		return (
			<div className={root} style={{ backgroundImage: `url('${bgImage.publicURL}')` }}>
				<div className={cn(modalWrapper, { [isOpenC]: isOpen })}>
					<span onClick={this.closeCrew}></span>
					<div className={modal}>
						<div className="btnClose btnFixed" onClick={this.closeCrew}></div>
						<h5 className={heading}>工作人員名單</h5>
						<p className={desc}>
						<span>計畫主持人：</span>李慈湄<br />
						<span>執行製作：</span>林頎姍、陳奕君<br />
						<span>網站設計製作：</span>吳亭儀<br />
						<span>鼓山洞音樂共創：</span>史旻玠<br />
						<span>音響技術統籌：</span>賴韋佑<br />
						<span>錄音師：</span>劉文奇<br />
						<span>音響技術人員：</span>鄭兆恩<br />
						<span>照明協力：</span>陳奕君<br />
						<span>鼓山洞影像團隊：</span>張沛瑜、王冠傑、林伯昂<br />
						<span>平溪礦坑影像導演：</span>李奎穆（阿賴耶影像工作室）<br />
						<span>網站動態影像設計：</span>李奎穆<br />
						<br /><br />
						<span>特別感謝：</span>高雄市西子灣文化創意協會、猴硐礦坑文史館、周朝南先生、潘子如先生、林潔妤小姐、藍貝芝小姐、李宥璇小姐、陳永清先生
						</p>
					</div>
				</div>
				<Wrapper size="L" className={wrapper} >
					<Logo className={logo} />
					<div className={content}>
						<div className={copyright}>
							<p>聯絡 Email：<a href="mailto:account@litzi-mei.com">account@litzi-mei.com</a>  李慈湄</p>
						</div>
						<p>本計畫由 CLAB當代文化實驗場 2020 年 CREATORS 創作／研發支持計畫補助。&nbsp;&nbsp;<span className={crewBtn} onClick={this.openCrew}>工作人員</span></p>
					</div>
				</Wrapper>
			</div>
		);
	}
}

IndexFooter.propTypes = {
	data: PropTypes.object.isRequired,
};

export default IndexFooter;