import Layout from 'components/Layout';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import CavesIndexTpl from 'templates/Caves/CavesIndexTpl';

const Tpl = props => (
  <Layout location={props.location}>
    <CavesIndexTpl {...props} />
  </Layout>
);
const CavesIndex = props => (
  <StaticQuery
    query={graphql`
      query CavesQuery {
        cavesPage: cavesJson {
          title
          meta {
            description
          }
          banner {
            title
            desc
            bgImage {
              publicURL
            }
          }
          caves {
            image {
              publicURL
            }
            text
            link
          }
          content
          videoUrl
          pictures {
            pictures
          }
          footer {
            bgImage {
              publicURL
            }
          }
        }
      }
    `}
    render={data => <Tpl data={data} {...props} />}
  />
);
Tpl.propTypes = {
  location: PropTypes.object,
};

export default CavesIndex;