import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import * as styles from './Section.module.scss';

const cx = classNames.bind(styles);

const Section = ({
  children,
  padding,
  className,
  style,
  id,
  Tag,
  center,
  ...other
}) => (
  <Tag
    className={cx(className, {
      center,
    })}
    style={style}
    id={id}
    {...other}
  >
    <div
      className={cx(styles.inner, {
        [`padding${padding}`]: padding,
      })}
    >
      {children}
    </div>
  </Tag>
);
Section.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.oneOf(['Blue', 'GreyBlue', 'Grey', 'Indigo']),
  padding: PropTypes.oneOf([
    'Xl',
    'L',
    'M',
    'S',
    'None',
    'TopXl',
    'TopL',
    'TopM',
    'TopS',
    'BottomS',
    'BottomM',
    'BottomL',
  ]),
  className: PropTypes.string,
  style: PropTypes.object,
  id: PropTypes.string,
  Tag: PropTypes.oneOf(['div', 'section']),
  center: PropTypes.bool,
};
Section.defaultProps = {
  padding: 'L',
  Tag: 'section',
  center: false,
};

export default Section;
