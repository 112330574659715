// extracted by mini-css-extract-plugin
export var center = "Section-module--center--tAgIn";
export var paddingBottomL = "Section-module--paddingBottomL--Tnyf+";
export var paddingBottomM = "Section-module--paddingBottomM--MkyB1";
export var paddingBottomS = "Section-module--paddingBottomS---lZm1";
export var paddingL = "Section-module--paddingL--0RrTg";
export var paddingM = "Section-module--paddingM--hB-pS";
export var paddingS = "Section-module--paddingS--2zLzZ";
export var paddingTopL = "Section-module--paddingTopL--XwA6k";
export var paddingTopM = "Section-module--paddingTopM--RRM2W";
export var paddingTopS = "Section-module--paddingTopS--gvQkj";
export var paddingTopXl = "Section-module--paddingTopXl--IQ1YP";
export var paddingXl = "Section-module--paddingXl--z5nCH";