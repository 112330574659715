import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import * as styles from './P.module.scss';
const cx = classNames.bind(styles);
const sizeOptions = ['M', 'S', 'L'];
const tag = ['div', 'p', 'span', 'h2', 'h3', 'h4', 'h5'];
const bottomOptions = ['M', 'S', 'Xs', 'Xxs'];

const P = ({
  Tag,
  size,
  bold,
  center,
  children,
  style,
  className,
  marginBottom,
}) => (
  <Tag
    className={cx(`size${size}`, className, {
      bold,
      center,
      [`marginBottom${marginBottom}`]: marginBottom,
    })}
    style={style}
  >
    {children}
  </Tag>
);
P.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(sizeOptions),
  Tag: PropTypes.oneOf(tag),
  bold: PropTypes.bool,
  center: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  marginBottom: PropTypes.oneOf(bottomOptions),
};
P.defaultProps = {
  size: 'M',
  Tag: 'div',
  bold: false,
};

export default P;
