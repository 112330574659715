import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import * as styles from './Heading.module.scss';

const cx = classNames.bind(styles);
const sizeOptions = ['L', 'M', 'SL', 'SM'];
const tag = ['h1', 'h2', 'h3', 'h4', 'h5', 'div', 'p'];
const bottomOptions = ['M', 'S', 'Xs', 'Xxs'];

const Heading = ({
  Tag,
  size,
  bold,
  center,
  marginBottom,
  children,
  style,
  className,
  ...other
}) => (
  <Tag
    className={cx(`size${size}`, className, {
      bold,
      center,
      [`marginBottom${marginBottom}`]: marginBottom,
    })}
    style={style}
    {...other}
  >
    {children}
  </Tag>
);
Heading.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(sizeOptions),
  Tag: PropTypes.oneOf(tag),
  bold: PropTypes.bool,
  center: PropTypes.bool,
  marginBottom: PropTypes.oneOf(bottomOptions),
  style: PropTypes.object,
  className: PropTypes.string,
};
Heading.defaultProps = {
  size: 'M',
  Tag: 'div',
  bold: false,
  center: false,
};

export default Heading;
