import Metadata from 'components/Metadata';
import PropTypes from 'prop-types';
import React from 'react';
import Banner from './Banner';
import Content from './Content';
import IndexFooter from './IndexFooter';


const CavesIndexTpl = ({ data }) => {
  const {
    cavesPage: {
      title,
      meta: { description },
      banner,
      caves,
      pictures,
      content,
      videoUrl,
      footer
    },
  } = data;
  return (
    <div>
      <Metadata title={title} description={description} />
      <Banner data={banner} caves={caves} />
      <Content data={{ content, videoUrl, pictures }} />
      <IndexFooter data={footer} />
    </div>
  );
};

CavesIndexTpl.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CavesIndexTpl;
