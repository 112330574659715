import { P, Wrapper } from 'components/base';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import ReactPlayer from 'react-player';
import { imageLi, imageList, root, video, videos } from './Content.module.scss';

class Content extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  openLightbox(index) {
    return this.setState({
      isOpen: true,
      photoIndex: index
    });
  }
  closeLightbox = () => this.setState({ isOpen: false });

  render() {
    const { data: { content, videoUrl, pictures } } = this.props;
    const images = pictures.pictures;
    const { photoIndex, isOpen } = this.state;
    return (
      <div className={root}>
        <Wrapper size="MS">
          <P center>{content}</P>
        </Wrapper>
        <Wrapper size="L">
          <ul className={videos}>
            {videoUrl.map((url ,i) => 
              <li key={i} className={video}>
                <ReactPlayer url={url} width="100%" />
              </li>
            )}
          </ul>
        </Wrapper>
        <Wrapper>
          
          <ul className={imageList}>
            {images.slice(0,3).map((item, i) =>
              <li key={i} className={imageLi} onClick={() => this.openLightbox(i)}>
                <img src={item} alt="photo" />
              </li>
            )}
          </ul>
          {isOpen && (
            <Lightbox
              mainSrc={images[photoIndex]}
              nextSrc={images[(photoIndex + 1) % images.length]}
              prevSrc={images[(photoIndex + images.length - 1) % images.length]}
              onCloseRequest={this.closeLightbox}
              imagePadding={40}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + images.length - 1) % images.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + 1) % images.length,
                })
              }
              enableZoom={false}
            />
          )}
        </Wrapper>
      </div>
    )
  }
}

Content.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.string.isRequired,
    videoUrl: PropTypes.array.isRequired,
    pictures: PropTypes.shape({
      pictures: PropTypes.array.isRequired,
    }),
  }),
};

export default Content;