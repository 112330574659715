// extracted by mini-css-extract-plugin
export var bold = "Heading-module--bold--BgmTT";
export var center = "Heading-module--center--GN84N";
export var marginBottomL = "Heading-module--marginBottomL--1KX5D";
export var marginBottomM = "Heading-module--marginBottomM--Hit3Y";
export var marginBottomS = "Heading-module--marginBottomS--o6KO8";
export var marginBottomXs = "Heading-module--marginBottomXs--e3z1y";
export var marginBottomXxs = "Heading-module--marginBottomXxs--TZGku";
export var sizeL = "Heading-module--sizeL--TeRfr";
export var sizeM = "Heading-module--sizeM--VqgE1";
export var sizeSL = "Heading-module--sizeSL--ED68v";
export var sizeSM = "Heading-module--sizeSM--mSRwL";