// extracted by mini-css-extract-plugin
export var content = "IndexFooter-module--content--IEUym";
export var copyright = "IndexFooter-module--copyright--Rz6Wr";
export var crewBtn = "IndexFooter-module--crewBtn--mrpxL";
export var desc = "IndexFooter-module--desc--BgQTV";
export var heading = "IndexFooter-module--heading--lutEK";
export var isOpenC = "IndexFooter-module--isOpenC--PRmmI";
export var logo = "IndexFooter-module--logo--13Rsi";
export var modal = "IndexFooter-module--modal--B3ALU";
export var modalWrapper = "IndexFooter-module--modalWrapper--m38iw";
export var root = "IndexFooter-module--root--dbBio";
export var wrapper = "IndexFooter-module--wrapper--LfCIh";