import { graphql, Link, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import 'react-image-lightbox/style.css';
import 'styles/components/_components.scss';
import 'styles/reset.css';
import 'styles/_global.scss';
import * as styles from './layout.module.scss';

class Layout extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      children,
      location,
      data: {
        site: {
          siteMetadata: { siteUrl },
        },
        siteJson: {
          meta: { favicon, ogImage, keyword },
        },
      },
    } = this.props;
    const url = `${siteUrl}${location.pathname}`;

    return (
        <div>
          <Helmet>
            <html lang="zh-TW" />
            <meta name="keywords" content={keyword} />
            <link rel="icon" href={favicon.publicURL} type="image/png" />
            <meta name="copyright" content="newsleopard" />
            <link rel="canonical" href={url} />
            <meta name="url" content={url} />

            <meta property="og:image" content={ogImage.publicURL} />
            <meta property="og:image:type" content="image/jpeg" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="newsleopard" />
            <meta property="og:url" content={url} />
            <meta name="twitter:card" content="summary" />

            {/* Start GA(gtag.js) */}
            {/* TODO */}
            {/* End GA(gtag.js) */}
          </Helmet>
          <div className={styles.topBack}>
            <Link to="/">Li Tzi-Mei 主頁</Link>
          </div>
          <main>
            <div className={styles.mobileContent}>
              手機版網站建製中⋯⋯
            </div>
            <div id="cursor"></div>
            {children}
          </main>
        </div>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        siteUrl: PropTypes.string.isRequired,
      }),
    }),
    siteJson: PropTypes.shape({
      meta: PropTypes.shape({
        favicon: PropTypes.shape({
          publicURL: PropTypes.string.isRequired,
        }),
        ogImage: PropTypes.shape({
          publicURL: PropTypes.string.isRequired,
        }),
        keyword: PropTypes.string,
      }),
    }),
  }),
};
Layout.defaultProps = {
  location: {
    pathname: '/',
  },
};

const comp = props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            env
            siteUrl
          }
        }
        siteJson {
          meta {
            favicon {
              publicURL
            }
            ogImage {
              publicURL
            }
            keyword
          }
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
);

export default comp;
