// extracted by mini-css-extract-plugin
export var bold = "P-module--bold--u7YkE";
export var center = "P-module--center--UhhSL";
export var marginBottomL = "P-module--marginBottomL--2bpDt";
export var marginBottomM = "P-module--marginBottomM--GANFQ";
export var marginBottomS = "P-module--marginBottomS--d9+Ga";
export var marginBottomXs = "P-module--marginBottomXs--1gAL8";
export var marginBottomXxs = "P-module--marginBottomXxs--ObfR9";
export var sizeL = "P-module--sizeL--QhDU3";
export var sizeM = "P-module--sizeM--Xv1DW";
export var sizeS = "P-module--sizeS--B3wmQ";