import Volumne from 'assets/caves/icons/volume.svg';
import Title from 'assets/caves/index/main-title.svg';
import Wrapper from 'components/Wrapper';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import * as styles from './Banner.module.scss';

class Banner extends Component {
	constructor(props) {
		super(props);
	}
	
	onMouseMove = e => {
		const cursor = document.querySelector('#cursor');
		cursor.style.left = `${e.pageX}px`;
		cursor.style.top = `${e.pageY}px`;
		cursor.style.display = 'block';
	}
	onMouseLeave = () => {
		const cursor = document.querySelector('#cursor');
		cursor.style.display = 'none';
	}
	onMouseEnter = () => {
		const cursor = document.querySelector('#cursor');
		cursor.style.display = 'block';
	}
	render() {
		const { data: { title, desc, bgImage }, caves } = this.props;
		return (
			<div className={styles.root} style={{ backgroundImage: `url('${bgImage.publicURL}')` }}>
				<section>
					<Wrapper>
						<h1 className={styles.titleWrapper}>
							<span className={styles.hidden}>{title}</span>
							<Title className={styles.title} />
						</h1>
						<div className={styles.desc}>
							<Volumne className={styles.icon} /><span>{desc}</span></div>
					</Wrapper>
				</section>
				<section className={styles.cavesWrapper}>
					<ul 
						className={styles.caves}
						onMouseMove={this.onMouseMove}
						onMouseLeave={this.onMouseLeave}
						onMouseEnter={this.onMouseEnter}
					>
						{
							caves.map(({ link, text, image }, i) => (
								<li key={i} className={styles.cave}>
									<Link to={link} className={styles.caveLink}>
										<img src={image.publicURL} alt={text} className={styles.caveImg} />
										<h1 className={styles.text}>{text}</h1>
									</Link>
								</li>
							))
						}
					</ul>
				</section>
			</div>
		)
	}
}

Banner.propTypes = {
	data: PropTypes.object.isRequired,
	caves: PropTypes.array.isRequired,
};

export default Banner;